@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  src: url(../assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: url(../assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
}
